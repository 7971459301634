import { getSite } from '@/utils/getSite';
import { getUrl } from '@/utils/getUrl';
import { http } from '@/utils/http';
import {
  BRAND,
  MediaItem,
  Playlist,
  SECTION,
  Show,
  TagsList,
  VideoFrontSection,
  VideoFrontSectionData,
  VideoTag,
} from './types';

export interface VideoFrontPageData extends TagsList {
  sections: VideoFrontSection[];
}

interface MediaPagination {
  page: number;
  total: number;
  limit: number;
}

const brand = getSite() as BRAND;

const getVideoServiceBase = () => {
  const url = process.env.OCELOT_VIDEO_SERVICE;
  if (!url) throw new Error('Missing env: OCELOT_VIDEO_SERVICE');
  return url;
};

const videoServiceEndpoint = (url: string) => {
  const base = getVideoServiceBase();
  if (!base) {
    return null;
  }
  return getUrl(url, base)?.toString();
};

export const getVideoFrontPageData = async (sections: SECTION[]) => {
  const fallback = { sections: [], tagsList: [] };
  if (!sections.length) {
    return fallback;
  }
  const url = videoServiceEndpoint(`/api/${brand}`);
  if (!url) {
    return fallback;
  }
  const response = await http.post<VideoFrontPageData>(url, {
    sections,
  });
  return response?.data ?? fallback;
};

export interface VideoMediaPage extends TagsList {
  media?: MediaItem;
  relatedMedia: Playlist[];
}

export const getVideoMediaPageData = async (mediaId: string): Promise<VideoMediaPage> => {
  const fallback: VideoMediaPage = { relatedMedia: [], tagsList: [] };
  const url = videoServiceEndpoint(`/api/${brand}/media/${mediaId}`);
  if (!url) {
    return fallback;
  }
  const response = await http.get<VideoMediaPage>(url);
  return response?.data ?? fallback;
};

export interface VideoTagPage extends TagsList {
  tagsMedia: Pick<VideoFrontSectionData, 'playlist'> & MediaPagination;
  objectTag: VideoTag;
}

export const getVideoTagPageData = async (tag: string, page?: string, limit?: string) => {
  const url = videoServiceEndpoint(`/api/${brand}/tag/${tag}`);

  if (!url) {
    return null;
  }

  const response = await http.post<VideoTagPage>(url, {
    page: Number(page) || undefined,
    limit: Number(limit) || undefined,
  });

  return response?.data;
};

// shows

export interface VideoAllShowsPageData extends TagsList {
  shows: { playlist: Playlist[] };
}

export const getAllShowsPageData = async (limit?: string, page?: string): Promise<VideoAllShowsPageData | null> => {
  const url = videoServiceEndpoint(`/api/${brand}/show`);

  if (!url) {
    return null;
  }
  const response = await http.post<VideoAllShowsPageData>(url, {
    data: { page: Number(page) || undefined, limit: Number(limit) || undefined },
  });

  return response?.data ?? null;
};

export interface VideoShowPlaylistPage extends TagsList {
  show: Show;
  relatedMedia: Playlist[];
}

export const getShowPlaylistPageData = async (playlistId: string): Promise<VideoShowPlaylistPage | null> => {
  const url = videoServiceEndpoint(`/api/${brand}/show/${playlistId}`);

  if (!url) {
    return null;
  }

  const response = await http<VideoShowPlaylistPage>(url);
  return response?.data ?? null;
};

export const getBrandAllMedia = async () => {
  const url = videoServiceEndpoint(`/api/${brand}/media`);

  if (!url) {
    return null;
  }

  const response = await http<{ url: string; publishedDate: string; tags: string[]; objectTags: VideoTag[] }[]>(url);
  return response?.data ?? null;
};
